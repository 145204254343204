.float {
    position: fixed;
    width: 50px;
    height: 50px;
    bottom: 40px;
    right: 40px;
    border-radius: 50px;
    text-align: center;

    @media screen and (max-width: 700px) {
        width: 40px;
        height: 40px;
        bottom: 20px;
        right: 20px;
    }
}