.card_course {
  border-radius: 10px;
  transition: 0.5s;

  .card_body {
    min-height: 12.5em;
  }

  .card_img_top {
    height: 160px;
    width: auto;
    object-fit: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .course_author {
    font-size: small;
  }

  .course_title {
    font-size: medium;
    color: #474747;
    margin: 5px auto;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .course_info {
    font-size: small;
    color: gray;
  }

  .rate_and_price {
    font-size: small;
  }

  .card_footer {
    border-radius: 0 0 10px 10px;
    min-height: 3.5em;
  }

  .blink {
    animation: blinker 2s linear infinite;
  }

  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
}

.card_course:hover {
  transform: translate(0, -10px);
  transition: 0.5s;

  .course_title {
    color: #14a7a0;
    -webkit-line-clamp: 5;
  }
}

@media screen and (max-width: 700px) {
  .card_course {
    border-radius: 10px;
    transition: 0.5s;

    .card_img_top {
      height: 230px;
      width: auto;
      object-fit: cover;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    .course_author {
      font-size: small;
    }

    .course_title {
      font-size: large;
      color: #474747;
      margin: 5px auto;
    }

    .course_level {
      font-size: small;
      font-weight: bold;
      color: gray;
    }

    .course_info {
      font-size: small;
      color: gray;
    }

    .rate_and_price {
      font-size: small;
    }
  }
}