.mega_dropdown {
    max-height: 300px;

    @media (max-width: 575.98px) {
        width: 300px;
    }

    @media (min-width: 576px) and (max-width: 767.98px) {
        width: 600px;
    }

    @media (min-width: 768px) and (max-width: 991.98px) {
        width: 800px;
    }

    @media (min-width: 992px) and (max-width: 1199.98px) {
        width: 1000px;
    }

    @media (min-width: 1200px) {
        width: 1100px;
    }
}