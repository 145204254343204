.ribbon_wrapper {
    width: 85px;
    height: 85px;
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;

    .ribbon {
        font-size: small;
        color: #fff;
        letter-spacing: .05em;
        line-height: 15px;
        text-align: center;
        text-shadow: 0 -1px 0 rgb(0 0 0 / 40%);
        transform: rotate(45deg);
        position: relative;
        padding: 7px 0;
        right: 6px;
        top: 18px;
        width: 120px;
        height: 28px;
        box-shadow: 0 0 3px rgb(0 0 0 / 30%);
    }
}

.img_wrapper {
    max-height: 120px;
    overflow: hidden;

    img {
        max-height: 120px;
    }
}

@media screen and (min-width: 1000px) {
    .info_wrapper {
        font-size: small;
    }
}