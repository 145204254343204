.card_myRoadmap {
    transition: 0.5s;
}

.card_myRoadmap:hover {
    transform: translate(0, -10px);
    transition: 0.5s;
}

.bg_gradient {
    background: rgb(2, 0, 36);
    background: linear-gradient(0deg, rgba(2, 0, 36, 0.7559438326111695) 45%, rgba(255, 255, 255, 0) 100%);
    border-radius: 0px 0px 15px 15px;
    height: 15em;
}