.timeline_with_icons {
    border-left: 2px solid hsl(0, 0%, 90%);
    position: relative;
    list-style: none;
}

.timeline_with_icons .timeline_item {
    position: relative;
}

.timeline_with_icons .timeline_item:after {
    position: absolute;
    display: block;
    top: 0;
}

.timeline_with_icons .timeline_icon {
    position: absolute;
    left: -48px;
    background-color: rgb(211, 226, 245);
    color: rgb(59, 59, 59);
    border-radius: 50%;
    height: 31px;
    width: 31px;
    display: flex;
    align-items: center;
    justify-content: center;
}