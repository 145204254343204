.notification_indicator {
    width: 12px;
    height: 12px;
    position: absolute;
    top: 10px;
    right: 12px;
    border-radius: 50%;
    border: 2px solid #fff;
    background-color: #f43f5e;
}

.dropdown_notification {
    max-height: 80vh;
    width: 30vw;

    @media screen and (max-width: 1200px) {
        width: 50vw;
    }

    @media screen and (max-width: 800px) {
        width: 65vw;
        left: 50%;
        transform: translateX(15%) !important;
    }

    @media screen and (max-width: 600px) {
        width: 90vw;
        left: 0;
        transform: translateX(20%) !important;
    }

    @media screen and (max-width: 400px) {
        width: 80vw;
        left: 0;
        transform: translateX(25%) !important;
    }
}