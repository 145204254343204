.sidebar_collapse {
    position: absolute;
    top: 0px;
    left: -25vw;
    height: 100vh;
    width: 25vw;
    transition: left 0.3s ease-in-out;
    background-color: #fff;
    border: 1px solid lightgray;

    ::-webkit-scrollbar {
        width: 8px;
    }

    ::-webkit-scrollbar-track {
        background: none;
    }

    ::-webkit-scrollbar-thumb {
        background-color: lightgray;
        border-radius: 20px;
        border: none;
    }
}

.sidebar_toggle {
    position: absolute;
    top: 5%;
    right: -45px;
    height: 45px;
    width: 45px;
    z-index: 1;
    background-color: #fff;
    border: 1px solid lightgray;
    border-radius: 0 10px 10px 0;
}

.sidebar_collapse.open {
    left: 0;
}

@media screen and (max-width: '768px') {
    .sidebar_collapse {
        left: -70vw;
        width: 70vw;
        z-index: 100;
    }

    .sidebar_toggle {
        position: absolute;
        top: 5%;
        right: -35px;
        height: 35px;
        width: 35px;
        z-index: 1;
        background-color: #fff;
        border: 1px solid lightgray;
        border-radius: 0 10px 10px 0;
    }
}