.gold {
  background: rgb(255, 211, 6);
  background: linear-gradient(
    90deg,
    rgba(255, 211, 6, 0.33318339542848385) 0%,
    rgba(255, 255, 255, 1) 61%
  );
  border-left: solid !important;
  border-left-color: #F49603 !important;
  border-left-width: 5px !important;
}

.silver {
  background: rgb(149, 149, 149);
  background: linear-gradient(
    90deg,
    rgba(149, 149, 149, 0.33318339542848385) 0%,
    rgba(255, 255, 255, 1) 61%
  );
  border-left: solid !important;
  border-left-color: gray !important;
  border-left-width: 5px !important;
}

.bronze {
  background: rgb(255, 150, 0);
  background: linear-gradient(
    90deg,
    rgba(255, 150, 0, 0.33318339542848385) 0%,
    rgba(255, 255, 255, 1) 61%
  );
  border-left: solid !important;
  border-left-color:#A76528 !important;
  border-left-width: 5px !important;
}

.blue {
  background: rgb(0, 201, 255);
  background: linear-gradient(
    90deg,
    rgba(0, 201, 255, 0.33318339542848385) 0%,
    rgba(255, 255, 255, 1) 61%
  );
}
