.btn_facebook {
    background: #3B5998;
    color: #fff;
}

.btn_facebook:hover {
    background: #273b66;
    color: #fff;
}

.btn_twitter {
    background: #00ACED;
    color: #fff;
}

.btn_twitter:hover {
    background: #008cbe;
    color: #fff;
}

.btn_linkedin {
    background: #0A66C2;
    color: #fff;
}

.btn_linkedin:hover {
    background: #0855a1;
    color: #fff;
}