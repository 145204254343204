@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

body {
  margin: 0;
  font-family: "Open Sans", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none !important;
}

// Main Color
$primary: #14a7a0;
$pink: #ff869a;
$light: #F5F7FA;

// Color
$light-orange: #fff7d9;
$light-green: #e2ffe5;
$light-blue: #eafdff;
$light-gray: #f8f9fa;
$enable-negative-margins: true;

[id] {
  scroll-margin-top: 120px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
}


// Breadcrumb
.breadcrumb-item.active {
  color: gray !important;
}

.breadcrumb-item+.breadcrumb-item::before {
  color: gray !important;
}

#navbar {
  .btn-primary {
    background-color: $primary;
    color: #fff;
    border: 0;
  }

  .link {
    color: #494949;
    text-decoration: none;
  }

  .link:hover {
    color: $primary;
  }

  .nav-link.active {
    font-weight: bold;
    color: $primary;
  }

  // Dropdown
  .dropdown-item.active,
  .dropdown-item:active {
    color: $primary;
    background-color: #dbdbdb;
  }
}

#main {

  // Font Color
  .text-pink {
    color: $pink;
  }

  // Background Color
  .bg-primary {
    background-color: $primary !important;
  }

  .bg-pink {
    background-color: $pink !important;
  }

  .bg-purple {
    background-color: #BD0AC0 !important;
  }

  .bg-light-green {
    background-color: $light-green;
  }

  .bg-soft-dark {
    background-color: #323841;
  }

  .bg-blue {
    background-color: #3995C9 !important;
  }

  .bg-yellow {
    background-color: #F4D86A !important;
  }

  // Link
  .link {
    text-decoration: none;
    color: gray;
    margin-bottom: 3px;
  }

  .link:hover {
    color: black;
  }

  // Rounded
  .rounded-2 {
    border-radius: 10px !important;
  }

  .rounded-3 {
    border-radius: 15px !important;
  }

  // Dropdown
  .dropdown-item.active,
  .dropdown-item:active {
    color: $primary;
    background-color: #dbdbdb;
  }

  // Section
  .section {
    .section-title {
      font-weight: 600;
      color: #717171;
    }
  }

  .bg-hero {
    background-color: #eaeef3;
  }

  .bg-document {
    background-color: #f5f7fa;
  }

  .bg-primary {
    background-color: $primary;
  }

  .bg-light2 {
    background-color: #f5f7fa;
  }

  .bg-light3 {
    background-color: #f0f2f5;
  }

  .bg-salmon {
    background-color: #ff8b99;
  }

  .btn-primary {
    background-color: $primary;
    color: #fff;
    border: none;
  }

  .btn-pink {
    background-color: $pink;
    color: #fff;
    border: 0;
  }

  .btn-soft-danger {
    color: #fff;
    background-color: #ff8b99 !important;
  }

  .card-rounded {
    border-radius: 12px;
  }

  .btn-rounded {
    border-radius: 10px;
  }

  // .btn-rounded:hover {
  //   background-color: #eaeaea;
  // }

  .btn-outline-primary {
    border: 1px solid $primary;
    color: $primary;
    background: none;
  }

  .btn-outline-primary:hover {
    color: #fff;
    background: $primary;
  }

  .img-rounded {
    border-radius: 12px 12px 0 0;
  }

  .card-rounded {
    border-radius: 15px;
  }

  .text-primary {
    color: $primary !important;
  }

  .mb-n-hero {
    margin-bottom: -7rem;
  }

  .objectfit-cover {
    object-fit: cover;
  }

  // Content Course & Course Detail Page
  .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    transition: left 0.3s ease-in-out;

    #customMarkdown {

      // Markdown
      .markdown {

        // Custom modal image size
        .__react_modal_image__medium_img {
          width: 80%;
          height: auto;
          object-fit: contain;
        }

        a {
          text-decoration: none;
          color: $primary;
        }

        p,
        ul,
        ol {
          color: inherit;
        }
      }
    }
  }

  .text-ellipsis-1 {
    font-size: medium;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .text-ellipsis-1:hover {
    -webkit-line-clamp: 5;
  }

  .text-ellipsis-2 {
    font-size: medium;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .text-ellipsis-2:hover {
    -webkit-line-clamp: 5;
  }

  table#transactionHistory {
    td {
      padding: 20px 0 !important;
    }
  }

  .content.open {
    left: 25vw;
    padding-left: 30px;
    height: 100vh;
  }

  @media screen and (max-width: 768px) {
    .mb-n-hero {
      margin-bottom: -3.5rem;
    }

    .content.open {
      left: 0;
      padding-left: 0;
    }
  }

  // Sidebar
  #sidebar {
    .nav-item {
      .active {
        background-color: $primary;
        border-radius: 8px;
        color: #fff !important;
      }

      .nav-link {
        padding: 10px 5px !important;
        text-align: left;
        height: auto;
      }

      .nav-link:not(.active):hover {
        background-color: $light;
        border-radius: 8px;
      }
    }
  }

  .nav:not(#navLeaderboard) {
    border-bottom: 2px solid #eaeaea;

    .nav-item {
      .active {
        background-color: transparent;
        color: $primary !important;
        border-radius: 0;
        border-bottom: 2px solid $primary;
      }

      .nav-link {
        color: #717171;
        padding: 0 30px;
        height: 40px;
        text-align: left;
      }
    }
  }

  .img-responsive {
    width: 100%;
  }

  @media screen and (max-width: 992px) {
    .img-responsive {
      width: 50%;
    }
  }

  #navTabSidebarCourseDetail {
    .nav-link.active {
      color: $primary !important;
      border-top: 4px solid $primary !important;
      border-radius: 0 !important;
    }

    .nav-link:not(.active) {
      color: #323841 !important;
      border: none !important;
      background-color: $light !important;
    }
  }
}

#learnHome,
#learnTutorial,
#learnWebinar {
  .swiper-pagination {
    padding-bottom: 10px;
  }

  .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    background-color: $primary;
  }
}

// Mobile Sidebar
#offcanvasSidebar {
  .nav-pills {
    .nav-link {
      color: #494949;
    }

    .active {
      background: $primary;
      color: #fff;
      border-radius: 10px;
    }
  }
}

#accordionSidebar {
  .accordion-button:not(.collapsed) {
    background-color: $light-gray;
  }

  // Timeline
  ul.timeline {
    list-style-type: none;
    position: relative;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  // ul.timeline:before {
  //   content: " ";
  //   background: #ebebeb;
  //   display: inline-block;
  //   position: absolute;
  //   border-radius: 15px;
  //   left: 15px;
  //   width: 3px;
  //   height: 100%;
  //   z-index: 400;
  // }

  ul.timeline>li {
    margin: 10px;
    padding-left: 0px;
  }

  // ul.timeline>li:before {
  //   content: " ";
  //   background: lightgray;
  //   display: inline-block;
  //   position: absolute;
  //   border-radius: 50%;
  //   border: none;
  //   left: 0.5em;
  //   width: 1em;
  //   height: 1em;
  //   z-index: 400;
  // }

  // ul.timeline>li.active:before {
  //   background: $primary;
  // }
}

#myRoadmap {
  .timeline-with-icons {
    border-left: 1px solid hsl(0, 0%, 90%);
    position: relative;
    list-style: none;
  }

  .timeline-with-icons .timeline-item {
    position: relative;
  }

  .timeline-with-icons .timeline-item:after {
    position: absolute;
    display: block;
    top: 0;
  }

  .timeline-with-icons .timeline-icon {
    position: absolute;
    left: -48px;
    border-radius: 50%;
    height: 31px;
    width: 31px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

#accordionModules {
  .accordion-button:not(.collapsed) {
    color: $primary !important;
    font-weight: bolder;
    background-color: $light-gray;
    // box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
  }
}

#customAccordion,
#sub-customAccordion {
  .accordion-button::after {
    flex-shrink: 0;
    width: 0;
    height: 0;
    content: "";
    background-image: none;
  }
}

// Link
.link {
  text-decoration: none;
  color: gray;
  margin-bottom: 3px;
}

.link:hover {
  cursor: pointer;
  color: $primary !important;
}

// Import Bootstrap
@import "~bootstrap/scss/bootstrap";